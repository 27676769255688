import { Issue } from "../models/issue";

export const issue: Issue = {
  type: null,
  client_issue_reason: null,
  doctor: null,
  message: {
    message: '',
    attachment: '',
    is_action_needed: false,
    is_text: false,
    is_attachment: false
  }
}