import { convertTo12HourFormat } from "./helpers";

export const statusList = ['Active', 'Onboarding', 'Waitlist', 'Inactive', 'Intake']

export const inactiveReasonsList = [
  {
    id: 1,
    name: 'Discharged'
  },
  {
    id: 2,
    name: 'Moved'
  },
  {
    id: 3,
    name: 'Graduated'
  },
  {
    id: 4,
    name: 'Other'
  }
]

export const cancellation_fee_allowed_list = [
  {
    id: 1,
    name: 'Allowed'
  },
  {
    id: 0,
    name: 'Not Allowed'
  }
]

export const is_driver_fee_allowed_list = [
  {
    id: 1,
    name: 'Allowed'
  },
  {
    id: 0,
    name: 'Not Allowed'
  }
]

export const is_driver_fee_list = [
  {
    id: 1,
    name: "Drive fee added",
  },
  {
    id: 0,
    name: "Drive fee not added",
  },
]


export const virtual_coverage_list = [
  {
    id: 'covered',
    name: 'Covered'
  },
  {
    id: 'not-covered',
    name: 'Not Covered'
  },
  {
    id: 'not-sure',
    name: 'Not sure'
  }
]

export const ageList = ['Minor', 'Adult']
export const sexList = ['Male', 'Female']

export const relationshipToInsuredList = ['Self', 'Spouse', 'Child', 'Other']

export const relationshipTypeList = ['Dad', 'Mom', 'Legal Guardian', 'Family Member', 'Physician', 'Partner', 'Spouse', 'Child', 'Dependent', 'Other']

export const planGroupTypes = [
  {
    id: 'PlanMedicare',
    name: 'Medicare'
  },
  {
    id: 'PlanMedicaid',
    name: 'Medicaid'
  },
  {
    id: 'PlanChampus',
    name: 'Tricare'
  },
  {
    id: 'PlanChampVA',
    name: 'ChampVA'
  },
  {
    id: 'PlanGroupHealthPlan',
    name: 'Group Health Plan'
  },
  {
    id: 'PlanFECA',
    name: 'FECA'
  },
  {
    id: 'PlanOther',
    name: 'Other'
  }
]

export const timezone = 'America/Los_Angeles';

export const issueReasonsList = [
  {
    id: 0,
    name: 'Deadline approaching'
  },
  {
    id: 1,
    name: 'Not authorized'
  }
]

export const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

export const modules = {
  toolbar: [
    // [{ header: '1' }, { header: '2' }, { font: [] }],
    // [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    // ['link', 'image', 'video'],
    // ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

export const passwordRegEx = {
  pattern: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/),
  numberRegex: new RegExp(/\d/),
  letterRegex: new RegExp(/.*[a-zA-Z].*/),
  uppercaseRegex: new RegExp(/[A-Z]/),
};

export const formatPrice = (value: number) => {
  const val = (value / 1).toFixed(2).replace('.', '.');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const sessionInterval = 15;

export const timeOptions = Array.from(Array(24).keys()).reduce(
  (time: unknown[], hour: number) => {
    Array.from(Array(60 / sessionInterval).keys()).map((i) => {
      const timeItem = (+`${hour}.${i * sessionInterval}`)
        .toFixed(2)
        .replace('.', ':');
      time.push({
        value: timeItem,
        label: convertTo12HourFormat(timeItem),
        isDisabled: false,
      });
      return null;
    });
    return time;
  },
  []
);

export const timeOptionsSchedule = (isEnd) =>
  timeOptions.filter((item: any) => {
    // Destructure the value to get the hours and minutes
    const [hours, minutes] = item.value.split(':').map(Number);

    // Only include times from 8:00 AM (8) to 5:00 PM (17)
    const isWithinRange = hours >= 8 && (isEnd ? hours <= 17 : hours < 17);

    // Include only ...:00 or ...:30
    const isStep30Min =
      minutes === 0 || (minutes === 30 && !(hours === 17 && minutes === 30));

    return isWithinRange && isStep30Min;
  });

console.log('timeOptions', timeOptions)

export const confirmModalCss = {
  '.MuiPaper-root': {
    width: '100%',
    maxWidth: '500px',
    flexDirection: 'column',
    maxHeight: 'calc(100% - 64px)',
    borderRadius: '10px',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#223354',
    padding: 0,
    boxShadow: '0 5rem 14rem 0 rgb(255 255 255 / 30%),0 0.8rem 2.3rem rgb(0 0 0 / 60%),0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
    margin: '32px',
    '.MuiDialogTitle-root': {
      margin: 0,
      fontSize: '15px',
      fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontWeight: 500,
      lineHeight: '1.6',
      padding: '16px 24px'
    },
    '.MuiButtonBase-root': {
      padding: '8px 20px',
      boxShadow: 'none',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: '10px',
      minWidth: '64px',
      fontSize: '0.875rem',
      lineHeight: '1.75',
      border: '1px solid rgba(85, 105, 255, 0.5)',
      color: '#5569ff'
    }
  }
}

export const provider_pi_status_list = [
  {
    id: 'credentialing-started',
    name: 'Credentialing started'
  },
  {
    id: 'active',
    name: 'Active'
  },
  {
    id: 'churned',
    name: 'Churned'
  }
]

export const provider_pi_source_list = [
  {
    id: 'linkedin',
    name: 'Linkedin'
  },
  {
    id: 'indeed',
    name: 'Indeed',
  },
  {
    id: 'pab',
    name: 'PAB'
  },
  {
    id: 'event',
    name: 'Event'
  },
  {
    id: 'referral',
    name: 'Referral'
  },
  {
    id: 'cold-email',
    name: 'Cold email'
  },
  {
    id: 'other',
    name: 'Other'
  }
]

export const provider_pi_type_list = [
  {
    id: 'oon-vet',
    name: 'OON Vet'
  },
  {
    id: 'inn-vet',
    name: 'INN Vet'
  },
  {
    id: 'oon-starter',
    name: 'OON Starter'
  }
]

export const provider_pi_slp_ot_pt_list = [
  {
    id: 'slp',
    name: 'SLP'
  },
  {
    id: 'ot',
    name: 'OT'
  },
  {
    id: 'pt',
    name: 'PT'
  }
]

export const status_insurance_list = [{ id: 'not-started', name: 'Not Started' }, { id: 'in-process', name: 'In-Process' }, { id: 'active', name: 'Active' }]